
$(document).ready(function () {
    AOS.init();
    var my_list = ($('.slider__item__title ul li'));
    for (let i = 1; i <= my_list.length; i++)
    {

    }
    var i = 1;                  
    function myLoop() {         
    setTimeout(function() {   
        let el = $(`li[data-num=${i}]`);  
        el.addClass('active')
        el.siblings().removeClass('active')
        
        i++;                    
        if (i <= my_list.length) {           
        myLoop();             
        }                       
    }, 2000)
    }
    myLoop();                   
    $(window).scroll(function () {
        $()
        var scroll = $(window).scrollTop();
        //>=, not <=
        if ((scroll == 0) && $('.header').hasClass('fixed')) {

            $(".header").removeClass("fixed");
            $('body').css('padding-top', 0)
        }
        else {
            $(".header").addClass("fixed");
            $('body').css('padding-top', $(".header").outerHeight())
        }
    }); //missing );
    $(document).on('click', '.search', function (e) {
        e.preventDefault();
        var $this = $(this);
        if ($this.hasClass('active')) {
            $this.siblings().css({ "visibility": "hidden", "opacity": "0", "bottom": "-100px" });
            $this.removeClass('active');

        }
        else {
            $this.siblings().css({ "visibility": "visible", "opacity": "1", "bottom": "-80px" });
            $this.addClass('active');

        }

    });
    if($('#map').lenght)
    {
        var map = L.map('map').setView([51.505, -0.09], 13);

        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        }).addTo(map);
    
        L.marker([51.5, -0.09]).addTo(map)
            .bindPopup('A pretty CSS3 popup.<br> Easily customizable.')
            .openPopup();
    }
    $(document).on('click','.header__mobile__list__icon i',function(e){
        e.preventDefault();
        var $this = $(this),
            parent = $this.parents('.header__mobile__list');
            parent.toggleClass('active');
        if(parent.hasClass('active'))
        {
            $this.removeClass('fa-solid fa-bars');
            $this.addClass('fa-solid fa-xmark');
        }
        else
        {
            $this.removeClass('fa-solid fa-xmark');
            $this.addClass('fa-solid fa-bars');
        }
        $('.header__mobile__menu').slideToggle('500');
        
    });

})
